import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { AhmData, CalculationAhm, CalculationManual, CreateDocumentDto, Flight, User, Workspace } from './weight-balance';
import {
	RouteNetwork,
	RouteNetworkDocMes,
	RouteNetworkItem,
} from '../administration-module/types/referance-route-network';
import { SettingsService } from '@core/services/settings/settings.service';
import { ElectronicDocument, ElectronicDocumentIncDto } from "./weight-balance";

@Injectable({
	providedIn: 'root',
})
export class WeightBalanceModuleRestApiService {
	private apiWeightBalanceUrl: string;
  private apiWeightBalanceElectronicDocumentFlowUrl: string;

	constructor(private http: HttpClient, private settingsService: SettingsService) {
		settingsService.general.applicationConfig$.subscribe(config => {
			this.apiWeightBalanceUrl = config.apiWeightBalanceURL;
      this.apiWeightBalanceElectronicDocumentFlowUrl = config.apiWeightBalanceElectronicDocumentFlowUrl;
		});
	}

	setDefaultHttpHeader(requestId?): object {
		// Формирование заголовков для отслеживания запросов
		// X-Correlation-ID идентификатор пользовательской сессии
		// X-Request-ID идентификатор события / запроса
		const httpOptions = {};
		httpOptions['headers'] = {
			'Content-Type': 'application/json',
			'X-Correlation-ID': this.settingsService.general.userSessionUuid,
			'X-Request-ID': requestId === undefined ? this.settingsService.general.randomUuid : requestId,
		};
		return httpOptions;
	}

	// Ahms
	getAhms(xRequestId?): Promise<AhmData[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<AhmData[]>(this.apiWeightBalanceUrl + '/ahm_data', httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	getAhm(id, xRequestId?): Promise<AhmData> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<AhmData>(this.apiWeightBalanceUrl + '/ahm_data/' + id, httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	getWorkspaces(): Promise<Workspace[]> {
		return this.http
			.get<Workspace[]>(this.apiWeightBalanceUrl + '/workspaces')
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	moveDesktop(workspaceId: number, flightId: number, xRequestId?): Promise<any> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put<any>(this.apiWeightBalanceUrl + `/workspaces/${workspaceId}/${flightId}`, null, httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	// Flights
	getFlights(filterParams?, workspace?, xRequestId?): Promise<Flight[]> {
		let url = '/flights';
		const params = [];
		if (filterParams) {
			for (const key in filterParams) {
				if ((key === 'start' || key === 'finish') && filterParams[key]) {
					params.push(key + '=' + filterParams[key].toISOString());
				} else if (filterParams[key] !== null && filterParams[key] !== '') {
					params.push(key + '=' + filterParams[key]);
				}
			}
		}
		if (workspace !== null) {
			params.push('workspace=' + workspace);
		}

		if (params.length > 0) {
			url += '?' + params.join('&');
		}

		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<Flight[]>(this.apiWeightBalanceUrl + url, httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	getFlight(id: number, xRequestId?): Promise<Flight> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<Flight>(this.apiWeightBalanceUrl + '/flights/' + id, httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	addFlight(flight: any, xRequestId?): Observable<HttpResponse<any>> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		httpOptions['observe'] = 'response';
		return this.http
			.post<HttpResponse<any>>(this.apiWeightBalanceUrl + '/flights', JSON.stringify(flight), httpOptions)
			.pipe(
				map(resp => {
					return resp;
				}),
				retry(1),
				catchError(this.handleError)
			);
	}

	updateFlight(flight: Flight, xRequestId?): Promise<Flight> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put<Flight>(this.apiWeightBalanceUrl + '/flights/' + flight.id, JSON.stringify(flight), httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	// Users
	getUsers(xRequestId?): Promise<User[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<User[]>(this.apiWeightBalanceUrl + '/admin/users', httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	setUser(id, assignee, xRequestId?): Promise<any> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.post<any>(this.apiWeightBalanceUrl + `/flights/${id}/assign/${assignee}`, null, httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	// Calculate
	calculate(calculate, xRequestId?): Observable<CalculationAhm> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.post<CalculationAhm>(this.apiWeightBalanceUrl + '/calculate', JSON.stringify(calculate), httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Chart data
	getChart(calculate, xRequestId?): Promise<any> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.post<any>(this.apiWeightBalanceUrl + '/chart', JSON.stringify(calculate), httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	// Get delta ballast
	getDeltaBallast(calculate, xRequestId?): Promise<any> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.post<any>(this.apiWeightBalanceUrl + '/ballast_delta', JSON.stringify(calculate), httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	// Get delta crew
	getDeltaCrew(calculate, xRequestId?): Promise<any> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.post<any>(this.apiWeightBalanceUrl + '/crew_delta', JSON.stringify(calculate), httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	// Calculations
	getCalculation(flightId, xRequestId?): Promise<CalculationAhm> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<CalculationAhm>(this.apiWeightBalanceUrl + '/calculations/' + flightId, httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	addCalculate(calculate: any, xRequestId?): Promise<HttpResponse<any>> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		httpOptions['observe'] = 'response';
		return this.http
			.post<HttpResponse<any>>(this.apiWeightBalanceUrl + '/calculations', JSON.stringify(calculate), httpOptions)
			.pipe(
				map(resp => {
					return resp;
				}),
				retry(1),
				catchError(this.handleError)
			)
			.toPromise();
	}

	updateCalculate(id, calculate, xRequestId?): Promise<CalculationAhm> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put<CalculationAhm>(this.apiWeightBalanceUrl + '/calculations/' + id, JSON.stringify(calculate), httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	// calculations manual
	getCalculationManual(flightId, xRequestId?): Promise<CalculationManual> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<CalculationManual>(this.apiWeightBalanceUrl + '/manual_loadsheet/' + flightId, httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	addCalculateManual(calculate: any, xRequestId?): Observable<HttpResponse<any>> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		httpOptions['observe'] = 'response';
		return this.http
			.post<HttpResponse<any>>(this.apiWeightBalanceUrl + '/manual_loadsheet', JSON.stringify(calculate), httpOptions)
			.pipe(
				map(resp => {
					return resp;
				}),
				retry(1),
				catchError(this.handleError)
			);
	}

	updateCalculateManual(id, calculate, xRequestId?): Promise<CalculationManual> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put<CalculationManual>(
				this.apiWeightBalanceUrl + '/manual_loadsheet/' + id,
				JSON.stringify(calculate),
				httpOptions
			)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	createDocuments(flightId, xRequestId?) {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.post(this.apiWeightBalanceUrl + '/documents/' + flightId + '/create', JSON.stringify(flightId), httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	loadDocument(flightId, edition, url, xRequestId?): Observable<any> {
		// return Observable.of('TODO: change to real data');
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<any>(this.apiWeightBalanceUrl + '/documents/' + flightId + '/editions/' + edition + url, httpOptions)
			.pipe(
				catchError(() => {
					this.http.options
					return of(undefined);
				})
			)
	}

	loadTelegram(flightId, tlgName, xRequestId?): Observable<any> {
		// return Observable.of('TODO: change to real data');
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<any>(this.apiWeightBalanceUrl + '/telegrams/' + flightId + '/' + tlgName, httpOptions)
			.pipe(
				catchError(() => {
					this.handleError;
					return of(undefined);
				}),
			)
	}

	documentIsSent(flightId, tlgName, xRequestId?): Observable<any> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<any>(this.apiWeightBalanceUrl + '/telegrams/' + flightId + '/' + tlgName, httpOptions)
			.pipe(
				catchError(() => {
					this.handleError;
					return of(undefined);
				}),
			)
	}

	sendTelegram(
		flightId: number,
		tlgName: string,
		addresses: Array<{ channel: number; address: string }>,
		xRequestId?
	) {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.post(
				this.apiWeightBalanceUrl + '/telegrams/' + flightId + '/' + tlgName + '/send',
				JSON.stringify(addresses),
				httpOptions
			)
			.pipe(retry(1), catchError(this.handleError));
	}

	loadRouteNetworks(
		airlineId: number,
		airports: Array<number>,
		type: string,
		name: string,
		xRequestId
	): Observable<{ channel: number; address: string }[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<RouteNetwork[]>(
				this.apiWeightBalanceUrl + '/admin/route_networks?airline=' + airlineId + '&airport=' + airports.toString()
			)
			.pipe(
				retry(1),
				map(data => {
					// собираем массив, channel - канал, address - куда отправляем
					const res = [];
					let flightType = 0;
					data.forEach((el: RouteNetwork) => {
						el[type].forEach((docItem: RouteNetworkDocMes) => {
							let airportIndex = airports.indexOf(el.airportId);
							// Проверка на наличие одного аэропорта в маршруте 2 раза,
							// для него надо установить тип Прилет/Вылет
							if (airportIndex !== -1 && airports.indexOf(el.airportId) !== airports.lastIndexOf(el.airportId)) {
								airportIndex = -1;
							}
							switch (airportIndex) {
								// Если первый пункт маршрута, то это Вылет
								case 0:
									flightType = 2;
									break;
								// Если последний пункт маршрута, то это Прилет
								case airports.length - 1:
									flightType = 1;
									break;
								// Все остальные варианты это Прилет/Вылет
								// если стоит в середине маршрута или встречается в нем
								// несколько раз
								default:
									flightType = 3;
									break;
							}
							if (docItem.arrDep === flightType || flightType === 3) {
								docItem.docs.forEach((doc: RouteNetworkItem) => {
									if (doc.name === name && doc.enabled) {
										const channel = el.addresses.find(address => address.address === docItem.address);
										if (channel) {
											res.push({ channel: channel.channelId, address: docItem.address });
										}
									}
								});
							}
						});
					});
					return res;
				}),
				catchError(this.handleError)
			);
	}

	getDocumentEditions(flightId, xRequestId?): Promise<[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<[]>(this.apiWeightBalanceUrl + '/documents/' + flightId + '/editions', httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	getLoadsheet(url, id, xRequestId): Observable<any> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http.get(this.apiWeightBalanceUrl + url + id, httpOptions).pipe(retry(1), catchError(this.handleError));
	}

	saveDocument(flightId, edition, url, xRequestId?): Observable<any> {
		let params = new HttpParams();
		params = params.append('export', 'pdf');
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		httpOptions['params'] = params;
		httpOptions['responseType'] = 'blob';
		return this.http
			.get(this.apiWeightBalanceUrl + '/documents/' + flightId + '/editions/' + edition + url, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// ahmData
	getAhmData(tailId, flightId, xRequestId?): Promise<AhmData> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<AhmData>(this.apiWeightBalanceUrl + '/ahm_data_by_tail/' + tailId + '/' + flightId, httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	// reference
	getReference(name, xRequestId?): Promise<any[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<any[]>(this.apiWeightBalanceUrl + '/master_data/' + name, httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

  // Electronic Document Flow
  getAllElectronicDocumentByFlightList(ids: number[], xRequestId?): Observable<ElectronicDocument[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<ElectronicDocumentIncDto[]>(this.apiWeightBalanceElectronicDocumentFlowUrl + '/documents/flights?ids=' + ids.join(','), httpOptions)
			.pipe(
        map(documentArray => {
          const result = [];
          documentArray.forEach(item => {
            const document = new ElectronicDocument();
            document._id = item._id;
            document.flight_id = item.flight_id;
            if (item.edition_list.length > 0) {
              Object.assign(document, item.edition_list[item.edition_list.length - 1]);
            }
            result.push(document);
          });
          return result;
        }),
        retry(1),
        catchError(this.handleError)
      )
	}

  getElectronicDocumentByFlight(id: number, xRequestId?): Observable<ElectronicDocument> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<ElectronicDocument>(this.apiWeightBalanceElectronicDocumentFlowUrl + '/documents/flights/' + id, httpOptions)
			.pipe(retry(1), catchError(this.handleError))
  }

  createElectronicDocument(document: CreateDocumentDto, xRequestId?): Observable<ElectronicDocument> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http
      .post<ElectronicDocument>(this.apiWeightBalanceElectronicDocumentFlowUrl + '/documents', JSON.stringify(document), httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  getElectronicDocumentByFlightAndEdition(id: number, edition: number, xRequestId?): Observable<ElectronicDocument> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<ElectronicDocumentIncDto>(this.apiWeightBalanceElectronicDocumentFlowUrl + `/documents/flights/${id}/edition/${edition}`, httpOptions)
			.pipe(
        map(item => {
          if (item) {
            const newDocument = new ElectronicDocument();
            newDocument._id = item._id;
            newDocument.flight_id = item.flight_id;
            if (item.edition_list.length > 0) {
              Object.assign(newDocument, item.edition_list[0]);
            }
          return newDocument;
          }
        }),
        retry(1),
        catchError(this.handleError)
      )
  }

	// Error handling
	handleError(error) {
		let errorMessage = '';
		let errorDetail: any = null;
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorDetail = error.error;
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		if (errorDetail) {
			return throwError(() => errorDetail);
		} else {
			return throwError(() => errorMessage);
		}
	}
}
