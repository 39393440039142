import { Module } from '@shared/models/module-name';
import { BehaviorSubject } from 'rxjs';

/**
 * Интерфейс для создания описания модуля системы
 */
export interface ModuleItem {
	id: Module; // Уникальный идентификатор модуля
	name: string; // Имя модуля на текущем языке приложения
	role: string; // Роль в Keycloak необходимая для доступа к модулю
	path: string; // URL для перехода к модулю
}

/**
 * Класс содержит список модулей системы
 */
export class Modules {
	readonly ROLE_PREFIX = 'aac_';
	// ! TODO Необходимо сделать преобразование ролей от наименований в Enum
	// ! для автоматической генерации наименований ролей
	// ! сейчас оставлено что бы не переделывать руками много пользователей
	// ! использовать функцию castRoleNameFromModuleName()

	availableForUser$ = new BehaviorSubject<ModuleItem[]>(null);

	// Список модулей системы
	private moduleList: ModuleItem[] = [
		{
			id: Module.Test,
			name: $localize`:@@app.components.test:Test`,
			role: 'aac_test',
			path: '/test',
		},
		{
			id: Module.WeightBalance,
			name: $localize`:@@app.components.weightBalance:Weight balance`,
			role: 'aac_balance',
			path: '/balance',
		},
		{
			id: Module.WeightBalanceMobile,
			name: $localize`:@@app.components.weightBalanceMobile:Weight balance mobile`,
			role: 'aac_balance-mobile',
			path: '/weight-balance-mobile',
		},
		{
			id: Module.LostFound,
			name: $localize`:@@app.components.lostFound:Lost and found`,
			role: 'aac_lostfound',
			path: '/lostfound',
		},
		{
			id: Module.ParkingPlace,
			name: $localize`:@@app.components.parkingPlace:Parking places`,
			role: 'aac_parking',
			path: '/parking',
		},
		{
			id: Module.Administration,
			name: $localize`:@@app.components.administration:Administration`,
			role: 'aac_admin',
			path: '/admin',
		},
		{
			id: Module.BoardingControl,
			name: $localize`:@@app.components.boardingControl:Boarding control`,
			role: 'aac_boardingcontrol',
			path: '/boardingcontrol',
		},
		{
			id: Module.MasterData,
			name: $localize`:@@app.components.masterData:Master data`,
			role: 'aac_masterdata',
			path: '/masterdata',
		},
		{
			id: Module.Reports,
			name: $localize`:@@app.components.reports:Log&Reports`,
			role: 'aac_reports',
			path: '/reports',
		},
		{
			id: Module.MobileApron,
			name: $localize`:@@app.components.mobileApron:Mobile apron`,
			role: 'aac_mobileapron',
			path: '/mobileapron',
		},
		{
			id: Module.Workflow,
			name: $localize`:@@app.components.workflow:Workflow`,
			role: 'aac_workflow',
			path: '/workflow',
		},
		{
			id: Module.SlotCoord,
			name: $localize`:@@app.components.slotCoord:Season`,
			role: 'aac_slotcoord',
			path: '/slotcoord',
		},
		{
			id: Module.Schedule,
			name: $localize`:@@app.components.schedule:Schedule`,
			role: 'aac_schedule',
			path: '/schedule',
		},
		{
			id: Module.ComMan,
			name: $localize`:@@app.components.comMan:Communication module`,
			role: 'aac_commodule',
			path: '/commodule',
		},
		{
			id: Module.MaterialAccounting,
			name: $localize`:@@app.components.materialAccounting:Material Accounting`,
			role: 'aac_materialaccounting',
			path: '/materialaccounting',
		},
	];

	/**
	 * Функция преобразования имени модуля к имени необходимой роли в Keycloak
	 * @example ModuleName преобразуется в prefix_module-name
	 * @param id Идентификатор модуля
	 * @returns Наименование роли для Keycloak
	 */
	private castRoleNameFromModuleName(id: Module): string {
		const name = Module[id].replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
		return this.ROLE_PREFIX + name;
	}

	/**
	 * Функция формирует список модулей доступных пользователю исходя из
	 * добавленных ролей в Keycloak
	 * @param roles Список ролей из учетной записи пользователя
	 */
	extractAvailableModulesForUserRoles(roles: Array<string>) {
		const availableModules: ModuleItem[] = [];
		roles.forEach(element => {
			const item = this.moduleList.find(item => item.role.toUpperCase() == element.toUpperCase());
			if (item != undefined) {
				availableModules.push(item);
			}
		});
		this.availableForUser$.next(availableModules);
	}

	/**
	 * Функция возвращает модуль с указанным идентификатором.
	 * @param {Module} id уникальный идентификатор модуля,
	 * который вы хотите получить.
	 */
	getModule(id: Module): ModuleItem {
		return this.availableForUser$.getValue().find(element => element.id == id);
	}

	/**
	 * Функция проверяет, доступен ли конкретный модуль текущему пользователю.
	 * @param {Module} id - Идентификатор модуля для проверки.
	 */
	isModuleAvailableForCurrentUser(id: Module): boolean {
		const item = this.availableForUser$.getValue().find(element => element.id == id);
		if (item !== undefined) {
			return true;
		} else {
			return false;
		}
	}
}
