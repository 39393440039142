import { Inject, inject, Injectable } from '@angular/core';
import { Socket, Manager } from 'socket.io-client';
import { NotificationMessage } from './models/notification-message';
import { SettingsService } from '../settings/settings.service';
import { NotificationSubscribe } from '../settings/user';
import { NotificationFilter } from './models/notification-filter';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { Howl } from 'howler';

@Injectable({
	providedIn: 'root',
})

/**
 * Сервис для отображения всплывающих браузерных уведомлений
 */
export class NotificationsService {
	settingService = inject(SettingsService);
	/// Поддержка браузером HTML5 Notifications
	private browserIsSupport = false;
	/// Права на отображения подтвержденным настройками сайта
	private permissionIsGranted = false;
	private _manager: Manager;
	private _socket: Socket = null;
	private _apiNotificationUrl = null;
	private _audio: Howl;
	SOCKET_SUBSCRIBE_EVENT = 'subscribe';
	SOCKET_NOTIFICATION_EVENT = 'notification';
	SOCKET_FILTER_EVENT = 'filter';
	notificationsSubscribe: NotificationSubscribe[] = [];
	notificationFilter: NotificationFilter = null;
	notificationInApp = inject(NzNotificationService);
	private _document = inject(DOCUMENT);
	private windowIsFocused = true;
	private _eventsSubject = new Subject<string>();
	public readonly events = this._eventsSubject.asObservable();

	constructor() {
		this.settingService.general.applicationConfig$.subscribe(config => {
			if (config.apiNotificationURL) {
				this._apiNotificationUrl = config.apiNotificationURL;
				this.initSocket();
			}
		});

		this.settingService.user.notificationsSubscribe$.subscribe(value => {
			if (value) {
				this.notificationsSubscribe = value;
				if (this._socket?.active) {
					this._socket.disconnect();
					this._socket.connect();
				}
			}
		});
	}

	initialization() {
		this.initBrowser();
		this.initAudio();
		this.initWindowEvents();
	}

	private initBrowser() {
		// Проверка поддержки браузером HMT5 Notification
		if ('Notification' in this._document.defaultView) {
			this.browserIsSupport = true;
			// Проверка прав на отображение уведомлений
			if (Notification.permission === 'granted') {
				this.permissionIsGranted = true;
				this.serviceWorkerRegistration();
			}
		} else {
			console.error('This browser does not support desktop notification');
		}
	}

	private initAudio() {
		this._audio = new Howl({
			src: ['assets/audio/notification-message-1.mp3'],
		});
	}

	private initWindowEvents() {
		// Подписка на событие потери фокуса в текущем окне
		this._document.defaultView.onblur = () => {
			this.windowIsFocused = false;
		};

		// Подписка на событие установки фокуса в текущее окно
		this._document.defaultView.onfocus = () => {
			this.windowIsFocused = true;
		};
	}

	connect() {
		this.subscribe();
		this.filter();
	}

	private initSocket() {
		this._manager = new Manager(this._apiNotificationUrl);
		this._socket = this._manager.socket('/');

		this._socket.on('connect', () => {
			this.connect();
		});
		this._socket.on('disconnect', this.disconnect);
		this._socket.on('notification', data => {
			this.notificationMessageHandler(data);
		});
	}

	disconnect(reason, details) {}

	subscribe() {
		this.notificationsSubscribe.forEach(item => {
			if (this?._socket) {
				this._socket.emit(this.SOCKET_SUBSCRIBE_EVENT, item.subscribe);
			}
		});
	}

	setFilter(filter: NotificationFilter) {
		this.notificationFilter = filter;
		this.filter();
	}

	filter() {
		if (this.notificationFilter === null) {
			return;
		}
		if (this._socket?.active) {
			this._socket.emit(this.SOCKET_FILTER_EVENT, JSON.stringify(this.notificationFilter));
		}
	}

	notificationMessageHandler(data: string) {
		try {
			const message: NotificationMessage = JSON.parse(data);
			this.show(message.title, message.message);
			this.emitEvent('wb-edm');
		} catch (e) {
			return console.error(e);
		}
	}

	private emitEvent(event: string) {
		this._eventsSubject.next(event);
	}

	/**
	 * Функция регистрации Service worker'a
	 */
	serviceWorkerRegistration() {
		if ('serviceWorker' in navigator) {
			this._document.defaultView.navigator.serviceWorker.register('assets/sw.js').then(
				registration => {
					console.log('Service worker registration succeeded:', registration);
				},
				error => {
					console.error(`Service worker registration failed: ${error}`);
				}
			);
		} else {
			console.error('Service workers are not supported.');
		}
	}

	/**
	 *  Функция возвращает состояние поддержки всплывающих уведомлений
	 * @returns true если всплывающие сообщения поддерживаются, иначе false
	 */
	isSupported(): boolean {
		return this.browserIsSupport;
	}

	/**
	 * Функция возвращает признак, разрешено показывать всплывающие уведомления
	 * @returns true если разрешение на показ установленно, иначе false
	 */
	permissionToShowIsGranted(): boolean {
		return this.permissionIsGranted;
	}

	/**
	 * Функция для запроса отображения всплывающих уведомлений
	 */
	requestPermission() {
		if (this.permissionIsGranted) {
			return;
		}

		Notification.requestPermission().then(permission => {
			if (permission === 'granted') {
				this.permissionIsGranted = true;
				this.serviceWorkerRegistration();
			} else {
				this.permissionIsGranted = false;
			}
		});
	}

	/**
	 * Функция для отображения всплывающего уведомления
	 * @param title Заголовок сообщения
	 * @param message Текст сообщения, до 32 символов
	 */
	show(title: string, message: string) {
		if (!this.permissionIsGranted || (document.visibilityState === 'visible' && this.windowIsFocused)) {
			this.showNotificationInApp(title, message);
		} else {
			this.showNotificationInSystem(title, message);
		}
	}

	private showNotificationInSystem(title: string, message: string) {
		navigator.serviceWorker.ready.then(registration => {
			registration
				.showNotification(title, {
					body: message,
					icon: '/assets/icons/icon-96x96.png',
					badge: '/assets/icons/icon-96x96.png',
				})
				.catch(error => {
					console.error('Ошибка при отправке сообщения через service worker-а:', error);
				});
		});
	}

	private showNotificationInApp(title: string, message: string): void {
		this.notificationInApp.blank(title, message.replace('\n', '<br>'), {
			nzPlacement: 'bottomRight',
			nzDuration: 30000,
		});
		this.playAudio();
	}

	private playAudio() {
		this._audio.play();
	}
}
