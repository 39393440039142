import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WeightBalanceMobileListComponent } from './weight-balance-mobile-list/weight-balance-mobile-list.component';
import { NgZorroAntdModule } from '../../../ng-zorro-antd.module';
import { WeightBalanceMobileItemComponent } from './weight-balance-mobile-item/weight-balance-mobile-item.component';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import {
	selectDesktops,
	selectDocument,
	selectElectronDocuments,
	selectFlights,
	selectTails,
} from '../state/weight-balance-mobile.selectors';
import {
	WeightBalanceMobileActions,
	WeightBalanceMobileReferenceActions,
} from '../state/weight-balance-mobile.actions';
import { Desktop, Flight, Tail } from '../models';
import { DocumentStatus, ElectronicDocument } from 'src/app/weight-balance-data/weight-balance';
import { Document, UpdateDocumentDto } from '../models/document';
import { FormsModule } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SettingsService } from '@core/services/settings/settings.service';
import { NotificationsService } from '@core/services/notifications/notifications.service';
import { FilterItem, NotificationFilter } from '@core/services/notifications/models/notification-filter';
import { SetItemFocusDirective } from '@shared/directive/set-item-focus.directive';

enum ViewState {
	SHOW_LIST,
	SHOW_ITEM,
}

enum DialogAction {
	APPROVED,
	REJECT,
}

@Component({
	selector: 'aac-weight-balance-mobile',
	standalone: true,
	imports: [
		FormsModule,
		CommonModule,
		NgZorroAntdModule,
		WeightBalanceMobileListComponent,
		WeightBalanceMobileItemComponent,
		SetItemFocusDirective,
	],
	templateUrl: './weight-balance-mobile.component.html',
	styleUrl: './weight-balance-mobile.component.less',
})
export class WeightBalanceMobileComponent implements OnInit, OnDestroy {
	currentFlight: Flight = null;
	username = '';

	currentViewState: ViewState = ViewState.SHOW_LIST;
	updateFlightIntervalId = null;
	ViewState = ViewState;

	tails$ = this.store.select(selectTails);
	tails: Tail[] = [];

	flights$ = this.store.select(selectFlights);
	flights: Flight[] = [];

	electronDocuments$ = this.store.select(selectElectronDocuments);
	electronDocuments: ElectronicDocument[] = [];

	selectDocument$ = this.store.select(selectDocument);
	selectDocument: Document = null;

	selectDesktops$ = this.store.select(selectDesktops);
	selectDesktops: Desktop[] = [];
	activeDesktop: Desktop = null;

	showConfirmActionDialog = false;
	codeForConfirmDialog = null;
	checkCodeStatusForConfirmDialog = '';
	DialogAction = DialogAction;
	confirmDialogType = DialogAction.APPROVED;
	confirmDialogNote = '';
	userEnteredCode = '';
	notificationsService = inject(NotificationsService);
	searchFlightNumber = null;

	@ViewChild(WeightBalanceMobileItemComponent)
	weightBalanceMobileItemComponent: WeightBalanceMobileItemComponent;

	constructor(private store: Store, private message: NzMessageService, private settingsService: SettingsService) {
		this.notificationsService.events.subscribe(data => {
			if (data === 'wb-edm') {
				this.loadFlights();
			}
		});
	}

	createMessage(text: string): void {
		this.message.create('error', text);
	}

	ngOnInit(): void {
		this.settingsService.user.login$.subscribe(login => {
			this.username = login;
		});

		this.loadDesktops();

		this.updateFlightIntervalId = setInterval(() => {
			this.loadFlights();
		}, 30000);
		this.store.dispatch(WeightBalanceMobileReferenceActions.getReferences());

		this.electronDocuments$.subscribe(data => {
			this.electronDocuments = data;
			this.assignElectronicDocumentToFlight();
		});

		this.tails$.subscribe(data => {
			this.tails = data;
		});

		this.selectDocument$.subscribe(data => {
			this.selectDocument = data;
		});

		this.selectDesktops$.subscribe(data => {
			data.forEach(item => {
				if (this.checkDesktopRule(item.id)) {
					this.selectDesktops.push(item);
				}
			});
		});

		this.flights$.subscribe(data => {
			const flightList = [];
			data.forEach(flight => {
				const tail = this.tails.find(tail => tail.id === flight.tailId);
				const newFlight: Flight = new Flight();
				Object.assign(newFlight, flight);
				if (tail !== undefined) {
					newFlight.tail = tail.tail;
				}
				newFlight.electronicDocument = this.flights.find(item => item.id === flight.id)?.electronicDocument;
				flightList.push(newFlight);
			});
			this.flights = flightList;
			this.getElectronicDocumentsForFlights(flightList);
		});
	}

	getElectronicDocumentsForFlights(flights: Flight[]) {
		const flightIdList = flights.map(flight => flight.id);
		if (flightIdList.length > 0) {
			this.store.dispatch(WeightBalanceMobileActions.getElectronicDocuments({ flightIds: flightIdList }));
		}
	}

	ngOnDestroy(): void {
		clearTimeout(this.updateFlightIntervalId);
	}

	assignElectronicDocumentToFlight() {
		this.electronDocuments.forEach(document => {
			const found = this.flights.find(flight => flight.id === document.flight_id);
			if (found !== undefined) {
				found.electronicDocument = document;
			}
		});
		this.flights = [].concat(this.flights);

		if (this.currentFlight) {
			const updateCurrentFlight = this.flights.find(flight => flight.id === this.currentFlight.id);
			if (updateCurrentFlight !== undefined) {
				this.currentFlight = updateCurrentFlight;
				this.weightBalanceMobileItemComponent.reloadDocument();
			}
		}
	}

	loadFlights() {
		this.store.dispatch(WeightBalanceMobileActions.getFlights({ workspace: this.activeDesktop.id }));
	}

	loadDesktops() {
		const mainDesktop = new Desktop();
		mainDesktop.id = 0;
		mainDesktop.name = 'Main';
		this.selectDesktops.push(mainDesktop);
		this.store.dispatch(WeightBalanceMobileActions.getDesktops());
		this.selectDesktop(mainDesktop);
	}

	checkDesktopRule(id: number): boolean {
		for (const element of this.settingsService.getWeightBalanceWorkspaces()) {
			if (Number(element.id) === id && element.rule === 'all') {
				return true;
			}
		}
		return false;
	}

	selectedFlight(id: number | null) {
		this.currentFlight = this.flights.find(f => f.id === id);
		if (id && this.currentFlight.electronicDocument) {
			this.store.dispatch(
				WeightBalanceMobileActions.getLoadSheet({
					flight: this.currentFlight.id,
					editions: this.currentFlight.electronicDocument.edition_number,
				})
			);
		} else {
			this.currentFlight = null;
			this.selectDocument = null;
		}

		this.changeViewState();
	}

	changeViewState() {
		if (this.currentFlight) {
			this.currentViewState = ViewState.SHOW_ITEM;
		} else {
			this.currentViewState = ViewState.SHOW_LIST;
		}
	}

	documentChange(id: number) {
		switch (id) {
			case 0:
				this.store.dispatch(
					WeightBalanceMobileActions.getLoadSheet({
						flight: this.currentFlight.id,
						editions: this.currentFlight.electronicDocument.edition_number,
					})
				);
				break;
			case 1:
				this.store.dispatch(
					WeightBalanceMobileActions.getLoadingInstruction({
						flight: this.currentFlight.id,
						editions: this.currentFlight.electronicDocument.edition_number,
					})
				);
				break;
		}
	}

	backToFlightList() {
		this.selectedFlight(null);
	}

	acceptFlightDocuments() {
		this.confirmDialogType = DialogAction.APPROVED;
		this.generateCodeForConfirmDialog();
		this.showConfirmActionDialog = true;
	}
	rejectFlightDocuments() {
		this.confirmDialogType = DialogAction.REJECT;
		this.generateCodeForConfirmDialog();
		this.showConfirmActionDialog = true;
	}

	confirmActionDialogCancel() {
		this.clearConfirmDialog();
		this.showConfirmActionDialog = false;
	}

	clearConfirmDialog() {
		this.confirmDialogNote = '';
		this.checkCodeStatusForConfirmDialog = '';
		this.userEnteredCode = '';
	}

	confirmActionDialogOk() {
		if (this.userEnteredCode == this.codeForConfirmDialog) {
			this.showConfirmActionDialog = false;
			const routeForDto = [];
			this.currentFlight.route.forEach((point, index) => {
				routeForDto.push({
					order: index,
					airport_iata: point.airportIata,
				});
			});
			const createDocumentDto = new UpdateDocumentDto(
				this.currentFlight.id,
				this.currentFlight.flightNumber,
				this.currentFlight.airlineIata,
				this.currentFlight.workspaceId,
				routeForDto,
				this.currentFlight.route[0].dtDeparture[0],
				this.currentFlight.electronicDocument.edition_number,
				this.username,
				new Date(),
				this.confirmDialogType === DialogAction.APPROVED ? DocumentStatus.APPROVED : DocumentStatus.REJECTED,
				this.confirmDialogNote
			);

			this.store.dispatch(WeightBalanceMobileActions.updateDocumentsStatus({ document: createDocumentDto }));
			this.clearConfirmDialog();
		} else {
			this.checkCodeStatusForConfirmDialog = 'error';
			this.createMessage('Incorrect code entered');
		}
	}

	generateCodeForConfirmDialog() {
		this.codeForConfirmDialog = Math.floor(Math.random() * 900 + 100);
	}

	selectDesktop(desktop: Desktop) {
		this.activeDesktop = desktop;
		this.setNotificationFilterForDesktop(desktop.id.toString());
		this.loadFlights();
	}

	setNotificationFilterForDesktop(desktopId: string) {
		const filterItem = new FilterItem('object', 'workspace_id', 'equal', desktopId);
		const filter = new NotificationFilter('WBL.Flight.Documents.Status', [filterItem]);
		this.notificationsService.setFilter(filter);
	}

	get flightsFiltered(): Flight[] {
		if (this.searchFlightNumber) {
			return this.flights.filter(item => item.flightNumber.indexOf(this.searchFlightNumber) != -1);
		}
		return this.flights;
	}
}
